import React from 'react'
import { graphql } from "gatsby"
import Layout from '../../components/layout'
import Seo from '../../components/seo'
import "./terms.css"

export const pageQuery = graphql`
  query {
    allMarkdownRemark(filter: {frontmatter: {title: {eq: "Terms"}}}) {
      nodes {
          html
        }
    }
  }
`

const Terms = ({data}) => {
    const markdown = data.allMarkdownRemark.nodes[0].html;
    return(
        <Layout
            pageClass="terms"
            offsetHeader={true}
        >
            <Seo title="Terms" />
            <section className="terms-section">
                <div className="terms-container container-max mt-50">
                    <div className="terms-copy font-lato p1" dangerouslySetInnerHTML={{ __html: markdown }} />           
                </div>
            </section>
        </Layout>
    )
}

export default Terms
